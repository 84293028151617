import '../sass/project.scss';

/* Project specific Javascript goes here. */

document.getElementById('links').onclick = function (event) {

    event = event
    var target = event.target
    var link = target.src ? target.parentNode : target
    var options = { index: link, event: event }
    var links = this.getElementsByTagName('a')
    blueimp.Gallery(links, options)
}


function showDescription(element) {
    const paragraph = element.querySelector('.description-text');
    paragraph.style.display = 'block';
}

function hideDescription(element) {
    const paragraph = element.querySelector('.description-text');
    paragraph.style.display = 'none';
}

